import type {
  Config,
  CallbackAction,
  Action,
  Settings,
} from '@brainfish-ai/widgets-common';
import { ActionType, WidgetType } from '@brainfish-ai/widgets-common';
import { fetchWithErrorHandling } from './utils/fetchWithErrorHandling';
import { isIOS14OrBelow } from './utils/isIOS14OrBelow';
import { sendBrainfishWidgetError } from './utils/sendBrainfishError';
import type { Brainfish } from './types/brainfish';

const API_HOST = import.meta.env.VITE_API_HOST;

const mapActionButtons = (actionButtons: (CallbackAction | Action)[]) =>
  actionButtons.map((action) =>
    action.type === ActionType.CALLBACK && action.value
      ? { ...action, value: new Function(`return ${action.value}`)() }
      : action
  );

const updateActions = (
  configActions: (Action | CallbackAction)[] = [],
  overrideActions: (Action | CallbackAction)[]
) => {
  const actionMap = new Map(
    configActions.map((action) => [action.label, action])
  );
  overrideActions.forEach((action) => actionMap.set(action.label, action));
  return Array.from(actionMap.values());
};

const transformConfig = ({
  config,
  apiKey,
}: {
  config: Config;
  apiKey: string;
}) => {
  const settings = config.settings || {};
  (
    ['bodyActionButtons', 'footerActionButtons', 'nextBestActions'] as const
  ).forEach((key) => {
    if (settings[key]) {
      settings[key] = mapActionButtons(
        settings[key] as (CallbackAction | Action)[]
      );
    }
  });

  return {
    ...config,
    settings,
    apiHost: API_HOST,
    widgetMode: config.widgetType,
    apiKey,
  };
};

const useUMD = isIOS14OrBelow();

const loadSearchWidgetScript = async (url: string): Promise<any> => {
  if (document.getElementById('brainfish-widget')) {
    return (window as any).Brainfish;
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.id = 'brainfish-widget';
    script.src = url;
    script.type = useUMD ? 'text/javascript' : 'module';
    script.async = true;

    script.onload = () => {
      setTimeout(() => {
        const module = (window as any).Brainfish;
        module
          ? resolve(module)
          : reject(new Error('Failed to load Brainfish module'));
      }, 200);
    };

    script.onerror = (event) => {
      const errorEvent = event as ErrorEvent;
      const errorDetails = {
        message: `Failed to load script: ${url}`,
        type: errorEvent.type,
        fileName: errorEvent.filename,
        lineNumber: errorEvent.lineno,
        columnNumber: errorEvent.colno,
        error: errorEvent.error ? errorEvent.error.toString() : 'Unknown error',
      };
      reject(new Error(JSON.stringify(errorDetails)));
    };

    if (!useUMD) {
      document.head.appendChild(script);
    }
  });
};

export const init = async ({
  widgetKey,
  overrides,
}: {
  widgetKey: string;
  overrides?: Pick<
    Settings,
    'nextBestActions' | 'bodyActionButtons' | 'footerActionButtons'
  >;
}) => {
  try {
    const endpoint = `${API_HOST}/api/searchWidgets.getConfigByKey`;
    const configResponse = await fetchWithErrorHandling(endpoint, widgetKey);
    const config = transformConfig({
      config: configResponse.config,
      apiKey: widgetKey,
    });

    if (overrides && config.settings) {
      (
        Object.entries(overrides) as [
          keyof Settings,
          (Action | CallbackAction)[]
        ][]
      ).forEach(([key, value]) => {
        if (key in config.settings) {
          (config.settings[key] as (Action | CallbackAction)[]) = updateActions(
            config.settings[key] as (Action | CallbackAction)[],
            value
          );
        }
      });
    }
    const version = config.version || 'latest';

    const url = `https://cdn.jsdelivr.net/npm/@brainfish-ai/search-widget@${version}/dist/web.js`;
    const scriptUrl = useUMD ? url.replace('web.js', 'web.umd.js') : url;

    const widget = await loadSearchWidgetScript(scriptUrl);

    return {
      widget,
      config,
    };
  } catch (error) {
    sendBrainfishWidgetError(error, (error as Error).message, widgetKey);
  }
};

function initializeWidget(widget: Brainfish, config: Config) {
  if (
    config.widgetType === WidgetType.Searchbar ||
    config.widgetType === 'Search'
  ) {
    widget.SearchWidget.initStandard(config);
  } else {
    widget.HelpWidget.initPopup(config);
  }
}

const initializedWidgets = new Set<string>();

export const initSearchWidget = async (options: {
  widgetKey: string;
  overrides?: any;
}): Promise<Brainfish | undefined> => {
  try {
    // Check if this widget has already been initialized
    if (initializedWidgets.has(options.widgetKey)) {
      // do nothing if already initialized
      return undefined;
    }

    const result = await init(options);
    if (result) {
      const { widget, config } = result;
      initializeWidget(widget, config);
      initializedWidgets.add(options.widgetKey);
      return widget;
    }
  } catch (error) {
    sendBrainfishWidgetError(
      error,
      (error as Error).message,
      options.widgetKey
    );
  }
  return undefined;
};
