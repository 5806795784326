// web.ts
// entry point for web component
import { initSearchWidget } from './initSearchWidget';
import type { Brainfish } from './types/brainfish';
import { BrainfishQueue, createBrainfishQueue } from './utils/brainfishQueue';

let globalBrainfish: BrainfishQueue | null = null;

function getBrainfishInstance(): BrainfishQueue {
  if (typeof window !== 'undefined' && (window as any).Brainfish) {
    return (window as any).Brainfish;
  }
  if (!globalBrainfish) {
    globalBrainfish = createBrainfishQueue();
    if (typeof window !== 'undefined') {
      (window as any).Brainfish = globalBrainfish;
    }
  }
  return globalBrainfish;
}

const Brainfish = getBrainfishInstance();

let initializationPromise: Promise<void> | null = null;

async function initializeBrainfish(options: {
  widgetKey: string;
  overrides?: any;
}): Promise<void> {
  const widget = await initSearchWidget(options);
  if (widget) {
    Brainfish._setRealBrainfish(widget);
  }
}

Brainfish.Widgets.init = async (options: {
  widgetKey: string;
  overrides?: any;
}): Promise<void> => {
  if (!initializationPromise) {
    // First initialization
    initializationPromise = initializeBrainfish(options);
  }

  // Wait for Brainfish to be initialized
  await initializationPromise;

  // Initialize this specific widget
  await initSearchWidget(options);
};

export default Brainfish;
